import React from 'react';
import { graphql } from 'gatsby';
import slugify from 'slugify';
import DefaultLayout from '@layouts';
import Meta from '@shared/Meta/Meta.query';
import FeaturedStoryContentPage from '@components/FeaturedStoryContentPage/FeaturedStoryContentPage';

const FeaturedStoryPage = ({ data }) => {
  const globalSiteData = data.allContentfulGlobalSiteData.edges.map(
    edge => edge.node
  )[0];

  const featuredStoryContent = data.allContentfulFeaturedStory.edges.map(
    edge => edge.node
  )[0];

  const { storyTitle } = featuredStoryContent;

  const {
    facebookLink,
    twitterLink,
    instagramLink,
    mailingAddress,
    cookieBannerCopy,
    cookieBannerCtaCopy,
    phoneNumber,
    contactEmails,
  } = globalSiteData;

  const footerData = {
    mailingAddress,
    phoneNumber,
    facebookLink,
    twitterLink,
    instagramLink,
    contactEmails,
  };

  const cookieBanner = {
    cookieBannerCopy,
    cookieBannerCtaCopy,
  };

  return (
    <DefaultLayout
      yellowLogo
      footerData={footerData}
      cookieBanner={cookieBanner}
      pageName={`Featured Story - ${slugify((storyTitle || '').toLowerCase())}`}
    >
      <Meta {...globalSiteData} />
      <FeaturedStoryContentPage featuredStoryContent={featuredStoryContent} />
    </DefaultLayout>
  );
};

export default FeaturedStoryPage;

// Graphql Query
export const pageQuery = graphql`
  {
    allContentfulGlobalSiteData {
      edges {
        node {
          ...Meta
        }
      }
    }

    allContentfulFeaturedStory {
      edges {
        node {
          storyEyebrow
          storyTitle
          storySubtitle
          storyParagraph1 {
            childMarkdownRemark {
              html
            }
          }
          storyParagraph2 {
            childMarkdownRemark {
              html
            }
          }
          storyParagraph3 {
            childMarkdownRemark {
              html
            }
          }
          storyQuoteImage {
            description
            fluid(maxWidth: 612, quality: 70) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          storyQuoteCopy
          storyQuoteAuthorName
          storyQuoteAuthorTitle
          storyImageCarousel {
            description
            fluid(maxWidth: 612, quality: 70) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`;
