import React from 'react';
import FeaturedStoryContent from '@components/FeaturedStories/FeaturedStoryContent';
import useWindowSize from '@hooks/useWindowSize';
import Styles from './FeaturedStoryContentPage.module.scss';

const FeaturedStoryContentPage = ({ featuredStoryContent }) => {
  const { windowWidth } = useWindowSize();

  return (
    <div className={Styles.featuredStoryContentPage}>
      <div className={Styles.backgroundMask} />
      <div className={Styles.featuredStoryPageWrapper}>
        <FeaturedStoryContent
          {...featuredStoryContent}
          windowWidth={windowWidth}
        />
      </div>
    </div>
  );
};

export default FeaturedStoryContentPage;
